@import '../../../styles/config.scss';

.container {
  .mobile {  
    @include sm {
      display: none;
    }
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .card {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .cardCont {
        display: flex;
        align-items: center;
        flex-direction: column;
        .form {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
      .cardActions {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
  .desktop {
    width: 100%;
    @media only screen and (max-width: 800px) {
      display: none;
    }
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    .card {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 35%;
      .cardCont {
        display: flex;
        align-items: center;
        flex-direction: column;
        .form {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
      .cardActions {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}

