@import '../../../styles/config.scss';

$imageBackground : url(https://res.cloudinary.com/dpka4vhu6/image/upload/v1531425330/judgemydressage/stockDressage1.jpg);

.whiteText {
  color: white;
}

//
// Intro Section
//

.backgroundImageDiv {
  background: 
  /* top, transparent black */ 
  linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.75) 100%),
  /* bottom, image */
  $imageBackground;
  background-size: cover;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  div.textDiv {
    padding-right: 15%;
    padding-left: 15%;
    padding-bottom: 10%;
    //Header text for small screens
    @media only screen and (max-width: 600px) {
      h1 {
        font-size: 1.7em !important;
      }
    }
  }
}

.container {
  margin-bottom: 50px;
  .getStarted {
    .startFlex {
      margin: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      h1 {
        margin-bottom: 7px;
      }
    }
    .cardCont {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include sm {
          width: 32%;
        }
      }
    }
  }
  .aboutFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    .aboutCardFlex {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 10px;
      .about {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        div {
          width: 100%;
          @include sm {
            max-width: 50%;
          }
        }
        img {
          width: 100%;
          @include sm {
            width: 35%;
          }
        }
      }
    }
  }
}
