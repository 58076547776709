.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  background: #212121;
  position: static;
  div.footerFlex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    p {
      padding: 0;
      margin: 0;
    }
  }
 
}

@media only screen and (max-width: 600px) {
  .toolbar {
    height: 80px;
    padding: 10px;
    div.footerFlex {
      justify-content: center;
      
    }
  }
}