@import '../../../styles/config.scss';

.container {
  display: flex;
  justify-content: space-between;
  .navButtonCont {
    display: flex;
    .button {
      color: white;
    }
    .button:not(:last-child) {
      margin-right: 30px;
    }
  }
}